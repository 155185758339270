<template>
  <div class="container">
    <alert v-if="patients.isLoading || !user" class="light-shadow" />
    <div
      class="header mb-3 mp-print-0"
      v-if="patientData && !patients.isLoading && user"
    >
      <div class="header-body mp-print-0">
        <h6 class="header-pretitle noPrint">Client</h6>
        <h1 class="header-title noPrint">
          {{ patientData.last }}, {{ patientData.first
          }}<span class="ml-3 h3">- ID {{ patientData.id }}</span>
        </h1>
        <div class="row">
          <div class="col-12">
            <patient-navbar />
          </div>
        </div>
      </div>
    </div>
    <router-view v-if="!patients.isLoading && patientData && user" />
  </div>
</template>
<script>
import { mapState } from "vuex";
import { default as PatientNavbar } from "./Navbar";
export default {
  name: "PatientsProfile",
  components: {
    PatientNavbar,
  },
  computed: {
    ...mapState(["patients"]),
    ...mapState({
      user: (state) => state.auth.user,
    }),
    id: function () {
      return this.$route.params.id;
    },
    patientData: function () {
      if (
        this.user &&
        this.user.isPatient &&
        this.patients &&
        this.patients.patient &&
        this.patients.patient.user_id != this.user.id
      ) {
        return {};
      }
      return this.patients.patient;
    },
  },
  methods: {
    checkPermission: function () {
      if (
        this.user &&
        this.user.isPatient &&
        this.patients &&
        this.patients.patient &&
        parseInt(this.patients.patient.user_id) != this.user.id
      ) {
        this.$router.push({ path: "/dashboard" });
      }
    },
  },
  beforeMount() {
    this.$store.dispatch("patients/get", { id: this.id });
  },
  watch: {
    user() {
      this.checkPermission();
    },
    patientData() {
      this.checkPermission();
    },
  },
};
</script>
