var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('ul',{staticClass:"nav nav-tabs header-tabs noPrint profile-nav-container"},_vm._l((_vm.displayedNav),function(link){return _c('li',{key:link.to || link.action,class:link.dropdown
          ? `nav-item dropdown ${link.class || ''}`
          : `nav-item ${link.class || ''}`},[(link.dropdown)?_c('a',{staticClass:"nav-link dropdown-toggle",attrs:{"href":"#","role":"button","data-toggle":"dropdown"}},[(link.icon)?_c('icon',{staticClass:"mr-1",attrs:{"type":link.icon}}):_vm._e(),(link.image)?_c('img',{staticClass:"mr-1",attrs:{"src":link.image.src,"width":link.image.width,"height":link.image.height}}):_vm._e(),_vm._v(" "+_vm._s(link.label)+" ")],1):_vm._e(),(link.dropdown)?_c('div',{staticClass:"dropdown-menu"},_vm._l((link.dropdown),function(drop){return _c('router-link',{key:drop.label,staticClass:"dropdown-item",class:_vm.name == drop.to ? `active` : ``,attrs:{"to":_vm.getLink(drop.to)}},[(drop.icon)?_c('icon',{staticClass:"mr-1",attrs:{"type":drop.icon}}):_vm._e(),(drop.image)?_c('img',{staticClass:"mr-1",attrs:{"src":drop.image.src,"width":drop.image.width,"height":drop.image.height}}):_vm._e(),_vm._v(" "+_vm._s(drop.label)+" ")],1)}),1):_vm._e(),(!link.dropdown && !link.action)?_c('router-link',{staticClass:"nav-link",class:_vm.name == link.to || (link.subLinks && link.subLinks.includes(_vm.name))
            ? `active`
            : ``,attrs:{"to":_vm.getLink(link.to)}},[(link.icon)?_c('icon',{staticClass:"mr-1",attrs:{"type":link.icon}}):_vm._e(),(link.image)?_c('img',{staticClass:"mr-1",attrs:{"src":link.image.src,"width":link.image.width,"height":link.image.height}}):_vm._e(),_vm._v(" "+_vm._s(link.label)+" "),(link.badgeKey)?_c('span',{staticClass:"badge badge-pill badge-danger ml-1"},[(
              link.badgeKey == 'budgets' &&
              !_vm.exhaustedBudgetsCountLoading &&
              _vm.exhaustedBudgetsCount
            )?_c('span',{attrs:{"title":"Exhausted Budgets Count"}},[_vm._v(" "+_vm._s(_vm.exhaustedBudgetsCount > 9 ? "9+" : _vm.exhaustedBudgetsCount)+" ")]):_vm._e()]):_vm._e()],1):_vm._e(),(!link.dropdown && link.action)?_c('a',{staticClass:"nav-link calendar-item-link",attrs:{"href":"/"},on:{"click":_vm.openCalendarModal}},[(link.icon)?_c('icon',{staticClass:"mr-1",attrs:{"type":link.icon}}):_vm._e(),(link.image)?_c('img',{staticClass:"mr-1",attrs:{"src":link.image.src,"width":link.image.width,"height":link.image.height}}):_vm._e(),_vm._v(" "+_vm._s(link.label)+" ")],1):_vm._e()],1)}),0),_c('sidebar',{staticClass:"calendar-modal",attrs:{"toggle":_vm.modalOpened,"title":`${_vm.patientData.name} Events`,"wrapClass":"calendar-tray"},on:{"close":_vm.closeCalendarModal}},[(_vm.modalOpened)?_c('Calendar',{key:Math.random(),attrs:{"profilePatientUserId":_vm.patientData.user_id},on:{"closeModal":function($event){_vm.modalOpened = false}}}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }